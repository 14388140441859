import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Logo from "./assets/Logo"
import Button from "./Button"
import { CTA, CTA_SMALL } from "../content/labels"
import { useWindowSize } from "../../hooks/useWindowSize"

const BarWrap = styled.div`
  /* position: absolute;
  top: 0px;
  left: 0px;
  width: 100%; */
  position: relative;
  z-index: 2;
`

const Bar = styled.div`
  display: flex;
  justify-content: center;
  color: ${p => p.color};
  padding: 10px 20px;
  /* margin-bottom: 20px; */
  align-items: center;

  a {
    display: block;
    text-decoration: none;
    color: ${p => p.color};
    margin: 0px;
    font-weight: normal;
    font-size: 2.5em;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Gotham Pro";
    font-weight: 900;
  }

  @media (max-width: 800px) {
    a {
      font-size: 2em;
    }
  }
`

const AppBar = props => {
  const { foregroundColor, backgroundColor, showCTA, center } = props

  const { width: containerWidth } = useWindowSize()

  const handleEdit = e => {
    e.preventDefault()
    navigate(`/editor}`)
  }

  const handleHome = e => {
    e.preventDefault()
    navigate("/")
  }

  const mobile = containerWidth < 800

  return (
    <BarWrap>
      <Bar color={foregroundColor} center={center}>
        <a href="/" onClick={handleHome}>
          <Logo
            color={foregroundColor}
            width={mobile ? 40 : 50}
            style={{ marginRight: 10 }}
          />
          TiltMaps
        </a>
        {showCTA ? (
          <Button
            onClick={handleEdit}
            backgroundColor={foregroundColor}
            foregroundColor={backgroundColor}
            label={mobile ? CTA_SMALL : CTA}
          />
        ) : null}
      </Bar>
    </BarWrap>
  )
}

AppBar.defaultProps = {
  showCTA: false,
  center: false,
  showBanner: false
}

export default AppBar
