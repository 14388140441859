import React from "react"
import styled from "styled-components"
import usePoster from "../../hooks/usePoster"

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${p => p.foregroundColor};
  background-color: ${p => p.backgroundColor};
  overflow: hidden;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;

  a {
    color: ${p => p.foregroundColor};
  }

  @media (max-width: 600px) {
    /* padding-left: 10px;
    padding-right: 10px; */
  }
`

const WrapContainer = ({ children, ...props }) => {
  const [poster] = usePoster()
  const backgroundColor = props.backgroundColor || poster.backgroundColor
  const foregroundColor = props.foregroundColor || poster.foregroundColor
  return (
    <Wrap
      {...props}
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
    >
      {children}
    </Wrap>
  )
}

export default WrapContainer
