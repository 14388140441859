import React from "react"
import styled from "styled-components"

const SkewCard = styled.div`
  position: relative;
  z-index: 2;
  margin: 0px auto;
  color: ${p => p.color};

  @media (max-width: 600px) {
    max-width: 90%;
  }

  &::after {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    width: 98%;
    height: 98%;
    color: ${p => p.color};
    background-color: ${p => p.backgroundColor};
    border: 3px solid ${p => p.color};
    border-radius: 6px;
    transform: skew(${p => p.skew}deg);
    @media (max-width: 700px) {
      transform: skew(${p => p.skew / 2}deg);
    }
  }
`

SkewCard.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  skew: 4
}

const Content = styled.div`
  padding: 60px;
  position: relative;
  z-index: 2;
  padding-top: 30px;
  @media (max-width: 600px) {
    padding: 30px;
  }
`

const SectionHeading = styled.h1`
  font-family: "Gotham Pro";
  font-weight: 900;
  font-size: 2.5em;
  text-align: center;
  z-index: 3;
  position: relative;
  top: 30px;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`

export default ({ children, heading, ...props }) => (
  <SkewCard {...props}>
    <SectionHeading>{heading}</SectionHeading>
    <Content>{children}</Content>
  </SkewCard>
)
